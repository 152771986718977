import React, { PureComponent } from 'react';
import ReactTree from '@/components/ReactTree'
import { routeTreeArr } from '@/js/routes/navbar'
import SidebarBrand from '@/components/ReactTree/components/SidebarBrand'
// import SidebarCta from '@/components/ReactTree/components/SidebarCta'
import { withRouter } from "react-router-dom";
class Navbar extends PureComponent {
    state = {

    }
    ReactTreeClickEvent = (obj) => {//多层级的点击事件
        // console.log(obj)

    }
    SidebarLinkFnTo = (obj) => {//单层级的点击事件
        this.props.history.push(obj._props.this_item.path)

    }
    active_condition = (obj) => {//单层选中后的事件监听
        // console.log(obj,9999)

    }

    render() {
        const { show } = this.props
        return (
            <ReactTree
                Navigation
                TreeData={routeTreeArr}
                ReactTreeClickEvent={this.ReactTreeClickEvent}
                active_condition={this.active_condition}
                SidebarLinkFnTo={this.SidebarLinkFnTo}
                // ScrollClick
                init
                SreachText={{ tree_text: this.props.tree_text, Sreachkey: 'name' }}
                Single={{
                    open_val: true,
                    Single_val_key: 'meta.title',
                    Single_header: false
                }}
                show={show}
                render={() => {
                    return {
                        head: <SidebarBrand title='供应商后台'></SidebarBrand>,
                        // bottom: <SidebarCta title='头部' middle='内容' toPage='/index'></SidebarCta>
                    }
                }}
            />

        );
    }
}

export default withRouter(Navbar);