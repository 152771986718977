import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import modal from './index.module.scss'
import Condition from '@/components/config/rif'
class Modal extends PureComponent {
    backdrop = React.createRef()
    modal = React.createRef()
    static propTypes = {
        title: PropTypes.string,
        isShow: PropTypes.bool.isRequired,//modal显示限制传入为布尔值
        modalClose: PropTypes.func,//传入关闭函数
        submitClose: PropTypes.func,//传入提交关闭函数
        evenClick: PropTypes.bool,//是否点击元素关闭
        show_submit: PropTypes.bool,
        zIndex: PropTypes.number,
        show_close: PropTypes.bool,
    }
    static defaultProps = {
        zIndex:999,
        title: "",   //模态框标题
        nextProps: false,   //modal显示
        close_text: "取消",  //取消按钮文本
        submit_text: "提交", //提交按钮文本
        subBtn_type: "primary", //提交按钮样式
        cloBtn_type: "secondary",   //关闭按钮样式
        header_type: "white",   //头部颜色按钮样式
        bgc_type: "light",    //背景颜色
        center: false,//弹框是否居中
        isShow: false,//是否点击元素关闭
        evenClick: false,//是否点击元素关闭
        show_submit: true,//显示提交按钮
        show_close: true,//显示取消按钮
        size: "md"   //默认中等大小
    }
    componentDidUpdate() {
        const { isShow } = this.props
        if (isShow && this.backdrop.current) {
            this.Showtimer()
        }
    }
    componentDidMount() {
        const { isShow } = this.props
        if (isShow && this.backdrop.current) {
            this.Showtimer()
        }
    }
    Showtimer = (val = false) => {
        const { isShow } = this.props
        const { current: { classList } } = this.backdrop
        const { current: { classList: classList2 } } = this.modal
        if (isShow && !val) {
            setTimeout(() => {
                classList.add('show', modal["show"])
                classList2.add('show', modal["show"])
            }, 250)
        } else {

            classList.remove(modal["show"])
            classList.remove('show')
            classList2.remove('show')
            classList2.remove(modal["show"])
        }

    }
    CloseFn = () => {
        const { modalClose } = this.props
        this.Showtimer(true)
        setTimeout(() => {
            modalClose()
        }, 250)
    }
    submitFn = () => {
        const { submitClose } = this.props
        if(submitClose){
            submitClose( this.CloseFn)
        }else{
            this.CloseFn()

        }
    }
    evenClickClose = () => {
        const { evenClick } = this.props
        if (evenClick) {
            this.CloseFn()
        }

    }
    classNameList = (val) => {
        const { bgc_type, center, size } = this.props
        switch (val) {
            case 'bgc_type':
                return bgc_type ? [modal['modal'], modal['modal-colored'], modal[`modal-${bgc_type}`], modal['fade'], 'fade'].join(' ') : [modal['modal'], modal['fade'], 'fade'].join(' ')
            case 'center':
                return center ? [modal["modal-dialog"], modal["modal-dialog-centered"], modal[`modal-${size}`]].join(' ') : [modal["modal-dialog"], modal[`modal-${size}`]].join(' ')
            default:
                break;
        }
    }
    render() {
        const {
            title,
            zIndex,
            isShow,
            close_text,
            submit_text,
            subBtn_type,
            cloBtn_type,
            header_type,
            show_submit,
            show_close } = this.props
        return (
            <Condition rif={isShow}>
                <div
                    className={this.classNameList('bgc_type')}
                    ref={this.modal} style={isShow ? {
                        display: "block",
                        zIndex
                    } : { display: "none" }}>
                    <div className={this.classNameList('center')}>
                        <div className={modal["modal-content"]}>
                            <div className={modal["modal-header"] + ' btn-' + header_type}>
                                <h5 className={modal["modal-title"] + ' btn-' + header_type} >{title}</h5>
                                <div  className={modal["close"]+" close"} onClick={this.CloseFn}>
                                    <span aria-hidden="true">&times;</span>
                                </div>
                            </div>
                            <div className={modal["modal-body"] + " m-3"} >
                                {this.props.render()}
                            </div>
                            <div className={modal["modal-footer"]}>
                                <Condition rif={show_close}>
                                    <button type="button" className={"btn btn-" + cloBtn_type} onClick={this.CloseFn} >{close_text}</button>
                                </Condition >
                                <Condition rif={show_submit}>
                                    <button type="button" className={"btn btn-" + subBtn_type} onClick={this.submitFn}>{submit_text}</button>
                                </Condition >

                            </div>
                        </div>
                    </div>
                    <div className={[modal["modal-backdrop"], modal["fade"], 'fade'].join(' ')} tabIndex="1" onClick={this.evenClickClose()} ref={this.backdrop} style={{ display: !isShow ? 'none' : 'block' }}> </div>
                </div >
            </Condition >
        );
    }
}
export default Modal;