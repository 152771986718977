import request from './axios';
// 管理员信息 - 修改密码
const AgentUpdatePassword = (data = {}) => {
    return new Promise((resolve, reject) => {
        request("post", '/Agent/updatePassword', data).then(res => {
            resolve(res);
        }, error => {
            console.log("网络异常~", error);
            reject(error)
        })
    })
}
// 供应商管理 - 修改
const AgentUpdate = (data = {}) => {
    return new Promise((resolve, reject) => {
        request("put", 'Agent/update', data).then(res => {
            resolve(res);
        }, error => {
            console.log("网络异常~", error);
            reject(error)
        })
    })
}


//供应商管理 - 列表
const AgentList = (data = {}) => {
    return new Promise((resolve, reject) => {
        request("get", 'Agent/list', data).then(res => {
            resolve(res);
        }, error => {
            console.log("网络异常~", error);
            reject(error)
        })
    })
}
//供应商管理 - 删除/批量删除
const AgentDelete = (data = {}) => {
    return new Promise((resolve, reject) => {
        request("delete", 'Agent/delete', data).then(res => {
            resolve(res);
        }, error => {
            console.log("网络异常~", error);
            reject(error)
        })
    })
}
// 供应商管理 - 新增
const AgentAdd = (data = {}) => {
    return new Promise((resolve, reject) => {
        request("post", 'Agent/add', data).then(res => {
            resolve(res);
        }, error => {
            console.log("网络异常~", error);
            reject(error)
        })
    })
}
// 加盟商管理 - 详情
const AgentGet= (data = {}) => {
    return new Promise((resolve, reject) => {
        request("get", 'Agent/get', data).then(res => {
            resolve(res);
        }, error => {
            console.log("网络异常~", error);
            reject(error)
        })
    })
}




export {
    AgentUpdate,
    AgentAdd,
    AgentList,
    AgentDelete,
    AgentGet,
    AgentUpdatePassword
}
