import { lazy } from 'react';
const Hotel = lazy(() => import('@/pages/Hotel'))
const changeData = lazy(() => import('@/pages/Hotel/pages/changeData'))
const HotelProduct = lazy(() => import('@/pages/HotelProduct'))
const HotelchangeData = lazy(() => import('@/pages/HotelProduct/pages/changeData'))
const HotelImageText = lazy(() => import('@/pages/HotelProduct/pages/ImageText'))
const HotelEnterPrice = lazy(() => import('@/pages/HotelProduct/pages/EnterPrice'))
const HotelEnterPriceBatch = lazy(() => import('@/pages/HotelProduct/pages/EnterPriceBatch'))
const RouteProduct = lazy(() => import('@/pages/RouteProduct'))
const RoutechangeData = lazy(() => import('@/pages/RouteProduct/pages/changeData'))
const RouteImageText = lazy(() => import('@/pages/RouteProduct/pages/ImageText'))
const RouteEnterPrice = lazy(() => import('@/pages/RouteProduct/pages/EnterPrice'))
const RouteEnterPriceBatch = lazy(() => import('@/pages/RouteProduct/pages/EnterPriceBatch'))
const Settlement = lazy(() => import('@/pages/Settlement'))
const Invoice = lazy(() => import( '@/pages/Settlement/pages/Invoice'))
const ProductOrder = lazy(() => import('@/pages/ProductOrder'))

export const routeTreeArr = [
   
    {
        meta: {
            title: '酒店管理',
        },
        children: [
            {
                path: '/HotelProduct?type=RobBuy',
                meta: {
                    title: '酒店抢购',
                },
            },
            {
                path: '/HotelProduct?type=Reserve',
                meta: {
                    title: '酒店预订',
                },
            },
            {
                path: '/Hotel',
                meta: {
                    title: '酒店管理',
                },
            },
        ]
    },
    {

        meta: {
            title: '路线管理',
        },
        children: [
            {
                path: '/RouteProduct?type=Parenting',
                meta: {
                    title: '亲子游',
                },
            },
            {
                path: '/RouteProduct?type=Periphery',
                meta: {
                    title: '周边游',
                },
            },
            {
                path: '/RouteProduct?type=Private',
                meta: {
                    title: '私家游',
                },
            },
        ]
    },
    {

        meta: {
            title: '订单管理',
        },
        children: [
            {
                path: '/ProductOrder?type=0',
                meta: {
                    title: '酒店订单',
                },
            },
            {
                path: '/ProductOrder?type=2',
                meta: {
                    title: '路线订单',
                },
            },
        ]
    },
    {

        meta: {
            title: '财务管理',
        },
        children: [
            {
                // active: true,
                path: '/Settlement',
                meta: {
                    title: '支出单',
                },
            },
        ]
    },
 


]
export const router = [
    {
        path: '/Hotel',
        component: Hotel
    },
    {
        path: '/changeData',
        component: changeData
    },
    {
        path: '/HotelProduct',
        component: HotelProduct
    },
    {
        path: '/HotelchangeData',
        component: HotelchangeData
    },
    {
        path: '/HotelImageText',
        component: HotelImageText
    },
    {
        path: '/HotelEnterPrice',
        component: HotelEnterPrice
    },
    {
        path: '/HotelEnterPriceBatch',
        component: HotelEnterPriceBatch
    },
    {
        path: '/RouteProduct',
        component: RouteProduct
    },
    {
        path: '/RoutechangeData',
        component: RoutechangeData
    },
    {
        path: '/RouteImageText',
        component: RouteImageText
    },
    {
        path: '/RouteEnterPrice',
        component: RouteEnterPrice
    },
    {
        path: '/RouteEnterPriceBatch',
        component: RouteEnterPriceBatch
    },
    {
        path: '/Settlement',
        component: Settlement
    },
    {
        path: '/ProductOrder',
        component: ProductOrder
    },
    {
        path: '/Invoice',
        component: Invoice
    },


]
export const redirect = '/Hotel'