import React from 'react'
// 引入编辑器组件
import BraftEditor from 'braft-editor'
import { ImageUploadImg } from "@/js/Interface/Image"

// 引入编辑器样式
import 'braft-editor/dist/index.css'
import PropTypes from 'prop-types'
import "./editor.scss"
import Modal from '@/components/Modal';
export default class EditorDemo extends React.Component {
  static propTypes = {
    htmlContent: PropTypes.string,
    saveEditorContent: PropTypes.func,
    onChange: PropTypes.func,
    save: PropTypes.bool,
    name: PropTypes.string,
    placeholder: PropTypes.string,

  }
  static defaultProps = {
    htmlContent: '',
    placeholder: '',
    save: true,
    saveEditorContent: () => {

    },
    onChange: () => {

    }

  }
  state = {
    isShow: false,
    // 创建一个空的editorState作为初始值
    editorState: BraftEditor.createEditorState(null)
  }

  async componentDidMount() {
    // 假设此处从服务端获取html格式的编辑器内容
    const { htmlContent } = this.props
    // 使用BraftEditor.createEditorState将html字符串转换为编辑器需要的editorStat
    this.setState({
      editorState: BraftEditor.createEditorState(htmlContent)
    })
  }

  submitContent = async () => {
    // 在编辑器获得焦点时按下ctrl+s会执行此方法
    // 编辑器内容提交到服务端之前，可直接调用editorState.toHTML()来获取HTML格式的内容
    const htmlContent = this.state.editorState.toHTML()
    this.props.saveEditorContent(htmlContent)
    if (htmlContent !== "<p></p>") {
      this.setState({ isShow: true })
    }
    // const result = await saveEditorContent(htmlContent)
  }

  handleEditorChange = (editorState) => {
    this.setState({ editorState }, () => {
      this.props.onChange( this.state.editorState.toHTML())
    })
  }
  modalClose = () => {
    this.setState({ isShow: false })
  }
  myUploadFn = async (param) => {
    const successFn = (response) => {
      // 假设服务端直接返回文件上传后的地址
      // 上传成功后调用param.success并传入上传后的文件地址
      param.success({
        url: response,
      })
    }

    // const progressFn = (event) => {
    //   // 上传进度发生变化时调用param.progress
    //   param.progress(event.loaded / event.total * 100)
    // }

    const errorFn = (response) => {
      // 上传发生错误时调用param.error
      param.error({
        msg: response
      })
    }
    const fd = new FormData()
    fd.append('img', param.file)
    const res = await ImageUploadImg(fd)
    const { code } = res
    //   progressFn()
    if (code === 0) {
      const { data } = res
      const { host, path } = data
      successFn(host + path)
    } else {
      errorFn(res.message)
    }

  }
  render() {

    const { editorState, isShow } = this.state
    const { placeholder, save } = this.props
    return (
      <div className="my-component">
        <Modal
          zIndex={9999}
          size='sm'
          title="提示"
          isShow={isShow}
          show_submit={false}
          bgc_type={'light'}
          modalClose={this.modalClose}
          close_text='确定'
          cloBtn_type='primary'
          header_type='primary'
          render={() => {
            return <p className="mb-0" style={{ textAlign: 'center', color: '#2469ce' }}>保存成功</p>
          }}>
        </Modal>
        {save ?
          <div className="btn">  <button type="button" className="btn btn-primary" onClick={this.submitContent}>保存</button></div>
          : ''}
        <BraftEditor
          className="bf-container"
          value={editorState}
          placeholder={placeholder}
          onChange={this.handleEditorChange}
          onSave={this.submitContent}
          media={{ accepts: { audio: false, video: false }, uploadFn: this.myUploadFn, }}
        />

      </div>
    )

  }

}