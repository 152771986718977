import React, { Component } from 'react'
import DayPickerInput from 'react-day-picker/DayPickerInput';
import moment from 'moment';
import { formatDate, parseDate } from 'react-day-picker/moment';
import 'react-day-picker/lib/style.css';
import { getNowFormatDate } from '@/js/tool.js'
import './index.scss'
// http://react-day-picker.js.org/docs/localization/
const MONTHS = [
  '一月',
  '二月',
  '三月',
  '四月',
  '五月',
  '六月',
  '七月',
  '八月',
  '九月',
  '十月',
  '十一月',
  '十二月',
];
const WEEKDAYS_LONG = [
  '星期日',
  '星期一',
  '星期二',
  '星期三',
  '星期四',
  '星期五',
  '星期六',
];

const WEEKDAYS_SHORT = ['日', '一', '二', '三', '四', '五', '六'];
export default class index extends Component {
  constructor(props) {
    super(props);
    this.handleFromChange = this.handleFromChange.bind(this);
    this.handleToChange = this.handleToChange.bind(this);
    this.state = {
      from: undefined,
      to: undefined,
    };
  }
  setValue = (from, to) => {
    const { handelChange, obj = false } = this.props
    const callBack = (value = "") => {
      this.setState({ from, to }, () => {
        handelChange({ target: { value } })

      });
    }
    if (!to || !from) {
      callBack()
    } else {
      const objValue = { state: getNowFormatDate(from), end: getNowFormatDate(to) }
      const strValue = getNowFormatDate(from) + ',' + getNowFormatDate(to)
      callBack(obj ? objValue : strValue)
    }
  }
  showFromMonth() {
    const { from, to } = this.state;
    if (moment(to).diff(moment(from), 'months') < 2) {
      this.to.getDayPicker().showMonth(from);
    }
    this.setValue(from, to)
  }

  handleFromChange(from) {
    // Change the from date and focus the "to" input field
    if (!from) {
      this.setValue(null, null)
    } else {
      this.setValue(from, from)
    }

  }

  clear = () => {
    this.setValue(null, null)
  }

  handleToChange(to) {
    const { from } = this.state;
    if (!to || !from) {
      this.setValue(null, null)
    } else {
      this.setState({ to }, this.showFromMonth);
    }

  }
  render() {
    const { from, to } = this.state;
    const FORMAT = 'YYYY/MM/DD';
    const modifiers = { start: from, end: to };
    return (
      <div className="InputFromTo DJsbAc">
        <DayPickerInput
          value={from}
          placeholder="开始"
          format={FORMAT}
          formatDate={formatDate}
          parseDate={parseDate}
          dayPickerProps={{
            selectedDays: [from, { from, to }],
            disabledDays: { after: to },
            toMonth: to,
            modifiers,
            numberOfMonths: 2,
            locale: "it",
            months: MONTHS,
            weekdaysLong: WEEKDAYS_LONG,
            weekdaysShort: WEEKDAYS_SHORT,
            firstDayOfWeek: 1,
            onDayClick: () => this.to.getInput().focus(),
          }}
          onDayChange={this.handleFromChange}
        />{' '}
        —{' '}
        <span className="InputFromTo-to">
          <DayPickerInput
            ref={el => (this.to = el)}
            value={to}
            placeholder="结束"
            format={FORMAT}
            formatDate={formatDate}
            parseDate={parseDate}
            dayPickerProps={{
              selectedDays: [from, { from, to }],
              disabledDays: { before: from },
              modifiers,
              month: from,
              fromMonth: from,
              numberOfMonths: 2,
              locale: "it",
              months: MONTHS,
              weekdaysLong: WEEKDAYS_LONG,
              weekdaysShort: WEEKDAYS_SHORT,
              firstDayOfWeek: 1,
            }}
            onDayChange={this.handleToChange}
          />
        </span>
        <button type="button" className="btn btn-primary" style={{ marginLeft: '10px' }} onClick={this.clear}>清空</button>
      </div>
    );
  }
}